import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

// Components
import BrandLayout from '../components/Branding/BrandLayout'

const BrandItem = props => {
  const { title, subTitle } = props.data.mdx.frontmatter

  return (
    <>
      <BrandLayout title={title} subTitle={subTitle} crumb={props}>
        <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
      </BrandLayout>
    </>
  )
}

export const pageQuery = graphql`
  query SinglePostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        subTitle
        slug
      }
      fileAbsolutePath
    }
  }
`

export default BrandItem
